
import Vue from 'vue'
import { isNotNested, parseData, parseForm } from '../../mixins/formData'

export default Vue.extend({
  name: 'AwardList',

  data: () => {
    return {
      award: null as any,
      errors: null,
      errorMsg: '',
      formName: 'admin_award_user_mail',
      formObj: {},
      formData: {} as any,
      isLoading: true,
      page: 1 as any,
    }
  },

  computed: {
    allAwards () {
      return this.$store.state.awards?.allAwards
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    currentPart () {
      return this.$route.params.part || this.$store.state.appInfo.awards.awardMailConfiguration.forms.admin_award_user_mail.parts[0].key
    },
    formParts () {
      if (!this.$store.state.appInfo) return null
      return this.$store.state.appInfo.awards.awardMailConfiguration.forms.admin_award_user_mail.parts
    },
  },

  methods: {
    changeAward () {
      this.switchContext(this.award)
    },

    init () {
      const { formData } = this.parseData(this.formParts, {}, this.formData)
      this.formData = Object.assign({}, this.formData, formData)
      this.isLoading = false
    },

    async sendMail () {
      console.log('sendMail: ');
      this.isLoading = true;

      // mail data
      let data = this.parseForm(this.formParts, this.formData)[this.currentPart]

      try {
        // signup request
        const response = await fetch(this.baseUrl + `crud/awardmail/${this.award}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': this.$store.state.user.apiToken,
          },
          body: JSON.stringify(data),
        })
        const res = await response.json()
        this.isLoading = false;
        if (response.status >= 400) {
          if (res) {
            throw new Error(JSON.stringify(res))
          } else {
            throw new Error('error sending mails')
          }
        } else {
          if (res) {
            console.log('res: ', res);
            const count = res.recipients.length
            this.$store.commit('setAlert', {
              type: 'info',
              message: `Es wurde(n) ${count} E-Mails versendet an:
              <div>${res.recipients.join(' ')}</div>`
            })
            this.formData = {}
            this.init();
          } else {
            throw new Error(res.error)
          }
        }
      } catch (error) {
        this.isLoading = false;
        let msg = 'Fehler beim versenden der Mails'
        if (error.message && error.message.indexOf('{') >= 0) {
          msg += ': ' + JSON.parse(error.message).message
        }
        this.$store.commit('setAlert', {
          type: 'error',
          message: msg
        })
        if (error.message.indexOf('errorObject') >= 0) {
          const errors = JSON.parse(error.message)
          this.errors = errors.errorObject
        }
        // this.errorMsg = 'Alle Pflichtfelder müssen ausgefüllt sein.'
      }
    },

    switchContext (val: any) {
      if (!val || val === 'null' || (this.currentAward && val === this.currentAward.id)) {
        return this.$store.commit('setCurrentAward', null)
      }
      this.award = val
      let res = this.allAwards?.filter((aw: any) => aw.id == this.award)[0]
      return this.$store.commit('setCurrentAward', res)
    },

    isNotNested,
    parseData,
    parseForm,
  },

  mounted () {
    if (this.$route.params.awardId && this.$route.params.awardId !== 'null') {
      this.award = this.$route.params.awardId
      this.switchContext(this.award)
    } else {
      if (this.currentAward?.id) {
        this.award = this.currentAward.id
      }
      this.init()
    }
  }
})
